import React, { Fragment } from "react";
import { EQuestionType, GameLanguages } from "../container/Enums/Enums";
import Step3 from "../assets/TTJrGame/Step3.jpg";
import Step4 from "../assets/TTGame/Step4.jpg";
import Step6 from "../assets/TTGame/Step6.png";
import Step7 from "../assets/TTGame/Step7.jpg";
import Step9 from "../assets/TTGame/timeroff.png";
import Step10 from "../assets/TTGame/Step10.jpg";
import Step11 from "../assets/TTGame/Step11.jpg";
import Step12 from "../assets/TTJrGame/Step12.jpg";
import EvidenceImage from "../assets/TTJrGame/evidence.jpg";
import ImageViewer from "../components/game/imagepanel/imagepanel";
import Recording from "../components/game/recordings/recordingPanel";
import { IQuestion } from "./TTMeta";
import TextFileSVG from "../assets/TTGame/textFile.svg";
import EvidencSVG from "../assets/TTGame/evidence.svg";
import Step1FooterMap from "../components/ttjr/Step1Map";
import LinkMap from "../components/ttjr/LinkMap";
// import ModelViewer from "../components/modelviewer/modelviewer";

// export const getQuestionByLanguage = (language: string): IQuestion[] => {
//   return Questions;
//   switch (language) {
//     case GameLanguages.ENUK:
//       break;
//     case GameLanguages.DUTCH:
//       break;
//     default:
//       return defaultQuestion;
//   }
// };

export const getGameQuestionsByLanguage = (language?: string): IQuestion[] => {
  switch (language) {
    case GameLanguages.ENUK:
      return Questions;
    case GameLanguages.DUTCH:
      return Questions;
    default:
      return Questions;
  }
};
const Questions: IQuestion[] = [
  {
    Id: "1",
    Type: EQuestionType.Dropdown,
    Question:
      "Which website is likely to contain more information about the bomb?",
    Hints: ["", "", ""],
    Options: [
      "",
      "St Stephen's Anglican Church",
      "City Discovery",
      "Google",
      "The Engineer",
      "Wikipedia",
      "Mind Games",
      "Steve the Bartender",
      "Youtube",
      "Bjorn's website",
      "Telstra",
    ],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        INTRO
      </span>,
      <span key={2} className={"mt-2"}>
        All right, we’ve just received a phone call from a terrorist threatening
        the blow up a building in the city in the next 60 minutes.
      </span>,
      <span key={3} className={"mt-3"}>
        We need to find the bomb and disarm it.
      </span>,
      <span key={4}>
        We then need to catch the terrorist. Let’s work together and do this.
      </span>,
      <span key={5} className={"mt-3"}>
        Here is the audio recording with the terrorist.
      </span>,
      <Recording
        key={6}
        header={"AUDIO RECORDING"}
        path={"assests/GameFiles/recording.mp3"}
      ></Recording>,
      <Fragment key={121}>
        <span style={{ color: "#7ED321", fontSize: "0.8em" }}>EVIDENCE</span>
        <span className={"mt-2"}>
          Our tech team managed to secure some evidence from his phone before he
          switched it off.
        </span>
        <span>
          We’ve also identified he’s somewhere in the east of Melbourne,
          Australia. But the call was too short for an exact location.
        </span>
        <span className={"mt-3"}>
          Before we look for him, let’s first find the bomb and disarm it.
        </span>
        <span>
          Hopefully one of the websites contains more information about its
          location and how to disarm it.
        </span>
        <span className={"mt-3"}>
          Can you have a look at the websites and inform me once you find a
          suspicious looking website that could contain more information?
        </span>
        <Step1FooterMap
          paths={[
            `${process.env.PUBLIC_URL}/assests/GameFiles/TTJr/evidence1.txt`,
            EvidenceImage,
          ]}
          images={[TextFileSVG, EvidencSVG]}
          itemNames={["Evidence_1.txt", "Evidence_2.png"]}
          footerText={"(click to enlarge)"}
        ></Step1FooterMap>
      </Fragment>,
    ],
    Content: [Step3],
  },
  {
    Id: "2",
    Type: EQuestionType.Input,
    Question:
      "That Bjorn Willy website looks very suspicious indeed. But it needs a password to log in. Can you tell us what the password is?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span style={{ color: "#7ED321", fontSize: "0.8em" }} key={1}>
        INTRO
      </span>,
      <span className={"mt-2"} key={2}>
        All right, we’ve just received a phone call from a terrorist threatening
        the blow up a building in the city in the next 60 minutes.
      </span>,
      <span key={3} className={"mt-3"}>
        We need to find the bomb and disarm it.
      </span>,
      <span key={5}>
        We then need to catch the terrorist. Let’s work together and do this.
      </span>,
      <span key={4} className={"mt-3"}>
        Here is the audio recording with the terrorist.
      </span>,
      <Recording
        key={6}
        header={"AUDIO RECORDING"}
        path={"assests/GameFiles/recording.mp3"}
      ></Recording>,
      <Fragment key={121}>
        <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
          EVIDENCE
        </span>
        <span key={2} className={"mt-2"}>
          Our tech team managed to secure some evidence from his phone before he
          switched it off.
        </span>
        <span key={3}>
          We’ve also identified he’s somewhere in the east of Melbourne,
          Australia. But the call was too short for an exact location.
        </span>
        <span key={4} className={"mt-3"}>
          Before we look for him, let’s first find the bomb and disarm it.
        </span>

        <span key={5}>
          Hopefully one of the websites contains more information about its
          location and how to disarm it.
        </span>
        <Step1FooterMap
          key={7}
          paths={[
            `${process.env.PUBLIC_URL}/assests/GameFiles/TTJr/evidence1.txt`,
            EvidenceImage,
          ]}
          images={[TextFileSVG, EvidencSVG]}
          itemNames={["Evidence_1.txt", "Evidence_2.png"]}
          footerText={"(click to enlarge)"}
        ></Step1FooterMap>
      </Fragment>,
    ],
    Content: [Step3],
  },
  {
    Id: "3",
    Type: EQuestionType.Input,
    Question:
      "Can you tell us the name of the location where the squad can find the bomb?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        WEBSITE
      </span>,
      <span key={2} className={"mt-2"}>
        Awesome work!
      </span>,
      <span key={3}>
        Now that we are on{" "}
        <a
          href={"https://www.bjornwilly.com.au"}
          target="_blank"
          rel="noreferrer"
          style={{ color: "red" }}
        >
          <u>his website</u>
        </a>
        , I'd suggest you split up and look at the contents.
      </span>,
      <ImageViewer
        key={4}
        linkImage={"https://www.bjornwilly.com.au"}
        viewerImage={Step3}
        footerText={"(Click to open website)"}
      ></ImageViewer>,
      <span key={11} className={"mt-2"}>
        We need to know the location of the bomb and hopefully there's also some
        information about the bomb itself.
      </span>,
      <span key={21}>
        Let me know once you figured out what the location is and I'll send the
        squad there.
      </span>,
    ],
    Content: [Step3],
  },
  {
    Id: "4",
    Type: EQuestionType.Input,
    Question:
      "What is the name of the document that contains more information about how to disarm the bomb?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        SUITCASE
      </span>,
      <span key={3} className={"mt-2"}>
        I'll send a squad to the location right away.
      </span>,
      <span key={2}>
        They are around the corner and should be there in a matter of seconds...
      </span>,
      // <ModelViewer key={4} path={"TT/SuitCase/suitcasev1.glb"}></ModelViewer>,
      <ImageViewer
        key={4}
        linkImage={Step4}
        viewerImage={Step4}
        defaultScale={0.9}
      ></ImageViewer>,
      <Fragment key={121}>
        <span key={1} className={"mt-2"}>
          Ok, they have arrived and found a suitcase.
        </span>
        <span key={2} className={"mt-2"}>
          Now we hope one of the documents contains more information on how to
          disarm the bomb.
        </span>
        <span key={3}>Let me know once you've found it! </span>
      </Fragment>,
    ],
    Content: [Step4],
  },
  {
    Id: "5",
    Type: EQuestionType.Input,
    Question: "What is the password to open the document? (case sensitive)",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        SUITCASE
      </span>,
      <span key={2} className={"mt-2"}>
        Ok, now that we know which document contains more information, we need
        to find the password to open it...
      </span>,
      // <ModelViewer key={4} path={"TT/SuitCase/suitcasev1.glb"}></ModelViewer>,
      <ImageViewer
        key={4}
        linkImage={Step4}
        viewerImage={Step4}
        defaultScale={0.9}
      ></ImageViewer>,
      <span key={11} className={"mt-2"}>
        Perhaps one of his emails contains more information.
        <br></br>
        Have a quick look at his emails and try to figure out the password to
        open the document.
      </span>,
    ],
    Content: [Step4],
  },
  {
    Id: "6",
    Type: EQuestionType.Input,
    Question: "What is step 1 of disarming the bomb?",
    Hints: ["", "", ""],
    Options: [""],

    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        DOCUMENT
      </span>,
      <span key={2} className={"mt-2"}>
        Great work team!
      </span>,
      <span key={3} className={"mt-2"}>
        The{" "}
        <a
          href={`${process.env.PUBLIC_URL}/assests/GameFiles/TTJr/madheaven.pdf`}
          target="_blank"
          rel="noreferrer"
          style={{ color: "red" }}
        >
          <u>document</u>
        </a>{" "}
        is open and the password was "libreville".
      </span>,
      <ImageViewer
        key={4}
        linkImage={`${process.env.PUBLIC_URL}/assests/GameFiles/TTJr/madheaven.pdf`}
        viewerImage={Step6}
        footerText={"(Click to open document)"}
      ></ImageViewer>,
      <Fragment key={121}>
        <span key={11} className={"mt-2"}>
          However, it seems disarming this bomb requires some expertise.
        </span>
        <span key={1}>
          Luckily you are the experts and I'm sure you're able to figure this
          out.
        </span>
        <span key={2} className={"mt-3"}>
          If you can go through the manual and let me know which steps to take
          to disarm the bomb, then I'll inform the squad.
        </span>
      </Fragment>,
    ],
    Content: [Step6],
  },
  {
    Id: "7",
    Type: EQuestionType.CheckBox,
    Question: "Which 3 wires do the squad need to cut?",
    Hints: ["", "", ""],
    Options: ["W14", "W19", "W21", "W23", "W43", "W59", "W64", "W71", "W81"],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        WIRES
      </span>,
      <span key={2} className={"mt-2"}>
        Great, the suitcase is open.
        <br></br>
        Time to start following the disarming steps.
      </span>,
      // <ModelViewer
      //   key={4}
      //   path={"TT/Bomb/timerbomb.glb"}
      //   zoomValue={2.5}
      // ></ModelViewer>,
      <ImageViewer
        key={4}
        linkImage={Step9}
        viewerImage={Step9}
        isDynamic={true}
      ></ImageViewer>,
      <Fragment key={121}>
        <span key={2} className={"mt-2"}>
          There's lots of wires and the squad isn't sure which ones they need to
          cut.
        </span>
        <span className={"mt-2"} key={4}>
          But they are sure you've got the skills to find out the correct wires
          to cut.
          <br></br>
          Have a close look at the bomb and the steps in the bomb manual.
        </span>
      </Fragment>,
    ],
    Content: [Step7],
  },
  {
    Id: "8",
    Type: EQuestionType.Input,
    Question: "What is the 4 digit confirmation code to disarm the bomb?",
    Hints: ["", "", ""],
    Options: [""],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 4,
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        CONFIRMATION CODE
      </span>,
      <span key={2} className={"mt-2"}>
        So far so good, that's probably all the wires.
      </span>,
      // <ModelViewer
      //   key={4}
      //   path={"TT/Bomb/timerbomb.glb"}
      //   zoomValue={2.5}
      // ></ModelViewer>,
      <ImageViewer
        key={4}
        linkImage={Step7}
        viewerImage={Step7}
        isDynamic={true}
      ></ImageViewer>,
      <span key={21} className={"mt-3"}>
        The next step is to find the confirmation code. For this model that
        seems to be a 4 digit code.
      </span>,
    ],
    Content: [Step7],
  },
  {
    Id: "9",
    Type: EQuestionType.Input,
    Question: "What is the last known location of the suspect?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        LAST KNOWN LOCATION
      </span>,
      <span key={2} className={"mt-2"}>
        Yes! That worked, the bomb is now disarmed.
      </span>,
      <span key={3} className={"mt-2"}>
        Thanks team, great work. You might want to consider a career at the
        Melbourne Police Department...
      </span>,
      <ImageViewer key={4} linkImage={Step9} viewerImage={Step9}></ImageViewer>,
      <Fragment key={121}>
        <span key={2} className={"mt-2"}>
          Now let's find the person responsible.
        </span>
        <span className={"mt-2"} key={4}>
          Have a look at his website and see if you can find anything related to
          his last known location.
        </span>
        <span key={3}>
          Also make sure to have another look at that{" "}
          <span style={{ display: "inline-flex", alignItems: "center" }}>
            <LinkMap linkText="evidence" image={EvidenceImage} />
          </span>{" "}
          we retrieved from the suspect's phone.
        </span>
      </Fragment>,
    ],
    Content: [Step9],
  },
  {
    Id: "10",
    Type: EQuestionType.Input,
    Question: "What is the address of the suspect?",
    Hints: ["", "", ""],
    Options: [""],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        LOCATION
      </span>,
      <span key={2} className={"mt-2"}>
        A squad is on its way to see if anyone at the church knows about him and
        where he could be...
      </span>,
      <ImageViewer
        key={4}
        linkImage={Step10}
        viewerImage={Step10}
      ></ImageViewer>,
      <Fragment key={121}>
        <span className={"mt-2"} key={3}>
          We're getting close now!
        </span>
        <span className={"mt-2"} key={4}>
          Someone at the church didn't know the exact address but gave us
          instructions on how to get to his place.
        </span>
        <span className={"mt-3"} key={6}>
          Can you tell me where to go and I'll inform the squad?<br></br>
          1. Start at the church at 360 Church Street in Richmond VIC
          <br></br>
          2. Go north until you reach Citizen's Park
          <br></br>
          3. Go right and take the 2nd left onto Griffiths Street
          <br></br>
          4. Go right until you get to a roundabout
          <br></br>
          5. Take the 1st exit and keep going straight on Gardner Street until
          the fork
          <br></br>
          6. At the fork, after 2 roundabouts, take the road to the left
          <br></br>
          7. Now you might have to take a good look by using Google
          Streetview...it's one of the houses on the right, with red bricks...at
          the beginning
          <br></br>
        </span>
      </Fragment>,
    ],
    Content: [Step10],
  },
  {
    Id: "11",
    Type: EQuestionType.RadioButton,
    Question: "Should the squad kick the door in or ask him to come out?",
    Hints: ["", "", ""],
    Options: ["Kick the door in", "Ask him to come out"],
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        AT THE HOUSE
      </span>,
      <span key={2} className={"mt-3"}>
        The squad has arrived at the house.
      </span>,
      <ImageViewer
        key={4}
        linkImage={Step11}
        viewerImage={Step11}
      ></ImageViewer>,
      <Fragment key={121}>
        <span key={2} className={"mt-2"}>
          Time to make a quick decision.
        </span>
        <span key={4}>
          They can either kick the door in or ask him to come out.
        </span>
        <span className={"mt-3"} key={6}>
          But we need to hurry up!
        </span>
      </Fragment>,
    ],
    Content: [Step11],
  },
  {
    Id: "12A",
    Type: EQuestionType.Input,
    Question: "What is the code to open the door?",
    Hints: ["", "", ""],
    Options: [],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 4,
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        INSIDE
      </span>,
      <span key={2} className={"mt-3"}>
        The squad kicked the door in and is now inside, but he has locked
        himself up in a room and the door to the room has a keypad. We need to
        hurry and get into that room!
      </span>,
      <ImageViewer
        key={4}
        linkImage={Step12}
        viewerImage={Step12}
      ></ImageViewer>,
      <Fragment key={121}>
        <span key={3} className={"mt-2"}>
          A squad member noticed a colourful clock that stopped ticking.
        </span>
        <span key={4} className={"mt-2"}>
          It seems a bit suspicious, perhaps it's a clue to the code of the
          door? Can you give us the code?
        </span>
      </Fragment>,
    ],
    Content: [Step12],
  },
  {
    Id: "12B",
    Type: EQuestionType.Input,
    Question: "What is the code to open the door?",
    Hints: ["", "", ""],
    Options: [],
    InputPattern: "^[0-9]*$",
    InputMaxLimit: 4,
    Body: [
      <span key={1} style={{ color: "#7ED321", fontSize: "0.8em" }}>
        INSIDE
      </span>,
      <span key={2} className={"mt-3"}>
        The squad ask him to come out, but he's shouting back that he's refusing
        to come outside.
      </span>,
      <span key={3}>
        Luckily the squad found a way in via the back entrance.
      </span>,
      <ImageViewer
        key={4}
        linkImage={Step12}
        viewerImage={Step12}
      ></ImageViewer>,
      <Fragment key={121}>
        <span key={3} className={"mt-3"}>
          But he has locked himself up in a room and the door to the room has a
          keypad.
        </span>
        <span key={4}>We need to hurry and get into that room!</span>
        <span key={5} className={"mt-2"}>
          A squad member noticed a colourful clock that stopped ticking. It
          seems a bit suspicious, perhaps it's a clue to the code of the door?
        </span>
      </Fragment>,
    ],
    Content: [Step12],
  },
];
