import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import GameStateContainer from "./context/GameState/GameStateContainer";
import Routes from "./container/Routes/Routes";
import AuthContextContainer from "./context/Auth/AuthContainer";
import Navbar from "./components/navbar/navbar";
import PopupContainer from "./context/Popup/PopupContainer";
import LoginImage from "./assets/Login/Login.jpg";
import TTMenuImage from "./assets/BG/MainMenu.jpg";
import BBHMenuImage from "./assets/BBHGame/BG/MainMenu.jpg";
import DetectiveIntro from "./assets/GameIntro/Intro.jpg";
import how_to_play_logo from "./img/menu/how_to_play_logo.svg";
import leaderboard_logo from "./img/menu/leaderboard_logo.svg";
import our_games_logo from "./img/menu/our_games_logo.svg";
import start_game_logo from "./img/menu/start_game_logo.svg";
import { CacheImages } from "./utilities/util";
import BTGameContainer from "./context/BTGameContext/BTGameContainer";

function App() {
  useEffect(() => {
    loadImages();
  }, []);
  const loadImages = () => {
    const Images = [
      LoginImage,
      how_to_play_logo,
      leaderboard_logo,
      our_games_logo,
      start_game_logo,
      TTMenuImage,
      BBHMenuImage,
      DetectiveIntro,
    ];
    CacheImages(Images);
  };
  return (
    <div>
      <BrowserRouter>
        <AuthContextContainer>
          <GameStateContainer>
            <BTGameContainer>
              <PopupContainer>
                <Navbar></Navbar>
                <Routes></Routes>
              </PopupContainer>
            </BTGameContainer>
          </GameStateContainer>
        </AuthContextContainer>
      </BrowserRouter>
    </div>
  );
}

export default App;
