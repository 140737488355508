import React from "react";
import classes from "./container.module.scss";
import { withRouter, RouteComponentProps } from "react-router-dom";
interface IContainer extends RouteComponentProps {
  style?: React.CSSProperties;
}
const GameContainer: React.FC<IContainer> = ({ style, history, children }) => {
  return (
    <div className={classes["menu"]} style={style}>
      {children}
    </div>
  );
};

export default withRouter(GameContainer);
