import React, { useContext, useEffect, useState } from "react";
import classes from "./button.module.scss";
import { getTranslatedWord } from "../../metadata/ButtonText";
import { GamestateContext } from "../../context/GameState/GameStateContext";

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  icon?: any;
  rightIcon?: any;
  color?: string;
  timer?: number;
}
const defaultProps: IButton = {
  color: "red",
  text: "LOG IN",
};
const Button: React.FC<IButton> = ({
  text,
  icon,
  color,
  rightIcon,
  timer,
  disabled,
  ...rest
}) => {
  const { gameLanguage } = useContext(GamestateContext); // Can be excluded...
  const [localTimer, setTimer] = useState(timer || 0);

  useEffect(() => {
    setTimer(timer || 0);
  }, [timer]);
  useEffect(() => {
    let intervalRef: any = null;
    if (localTimer >= 0) {
      intervalRef = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => {
      if (intervalRef) clearInterval(intervalRef);
    };
  }, [localTimer]);
  const defaultStyle = {
    width: "50%",
    height: "50%",
    borderRadius: "29px",
    margin: "0",
  };
  const styles = {
    ...defaultStyle,
    ...rest.style,
  };
  return (
    <button
      {...rest}
      className={`${classes["login-button"]} ${
        classes[`login-button-${color}`]
      } ${rest.className}`}
      style={styles}
      disabled={disabled || localTimer > 0}
    >
      {rightIcon}
      {"\xA0"}
      {getTranslatedWord(text, gameLanguage)}
      {"\xA0"}
      {icon}
      {!!(localTimer > 0) && `(${localTimer})`}
      {}
    </button>
  );
};

Button.defaultProps = defaultProps;
export default Button;
