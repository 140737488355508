import React, { Fragment, useContext, useEffect, useState } from "react";
import classes from "./Menu.module.scss";
import { Container } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router";
import how_to_play_logo from "../../img/menu/how_to_play_logo.svg";
import leaderboard_logo from "../../img/menu/leaderboard_logo.svg";
import our_games_logo from "../../img/menu/our_games_logo.svg";
import start_game_logo from "../../img/menu/start_game_logo.svg";
import Card from "../../components/gamemenu/menucard";
import active_menu_card from "../../img/menu/menu_card_active.png";
import active_menu_card_result from "../../assets/ResultScreen/SlotColor.png";
import { GamestateContext } from "../../context/GameState/GameStateContext";
import { getGameResult } from "../../metadata/TTResult";
import Victoria_Logo from "../../assets/Logo/VicScreen_VicGovt_Lock_Up_W.png";
import clock from "../../assets/GameIntro/clock.svg";
import twomen from "../../assets/GameIntro/two-men.svg";
import xmark from "../../assets/GameIntro/x-mark.svg";

import {
  EGameCompany,
  EGameScreen,
  EGameState,
  EMessageType,
  GameTypes,
} from "../Enums/Enums";
import { CircularSpinner } from "../../components/Spinner/Spinner";
import NetworkManager from "../../services/api.services";
import { AuthContext } from "../../context/Auth/AuthContext";
import { GameStatusResponse, HintUsed } from "../Interfaces/CustomInterfaces";
import { getMessageBox } from "../../components/game/questionpanel/choosepanel";
import { CacheImages, Clamp } from "../../utilities/util";
import { PopupContext } from "../../context/Popup/PopupContext";
import { getFullGameName } from "../../components/game/hud/hud";
import { GameLanguages } from "../Enums/Enums";
import { getTranslatedWord } from "../../metadata/ButtonText";
import "../../Background.scss";
import {
  defaultGameState,
  IGameState,
} from "../../context/GameState/GameStateInterface";
import {
  GAME_TIME,
  MAX_USERS,
  ROUTE_HOW_TO_PLAY,
  ROUTE_LEADERBOARD,
  ROUTE_RESULT,
  ROUTE_RESUME_INTRO,
  WEBSITE_LINK,
  ROUTE_START_INTRO,
  WEBSITE_LINK_NL,
  CHANNEL_JOIN_SUCCESS,
  GAME_COMPANY,
  CHANNEL_JOIN_FAILURE,
  START_GAME,
  ENIGMA_WEBSITE_LINK,
  FUN_EMPIRE_WEBSITE_LINK,
  ESCAPE_ROOM_WEBSITE_LINK,
  ESCAPE_HQ_WEBSITE_LINK,
  GAME_TYPE,
  ESCAPE_GET_ME_OUT_LINE,
  GRAND_ESCAPE_GAME_LINK,
} from "../../constants";
import {
  getGameQuestions,
  getGameBackground,
  getGameBGByScreen,
} from "../../metadata/TTMeta";
import Button from "../../components/button/button";
import pusher from "../../config/pusher";
import logger, { FormatMessageByCode } from "../../services/logger";

const Menu: React.FunctionComponent<RouteComponentProps> = ({ history }) => {
  const [isLoading, setLoading] = useState(false);
  const [state, setState] = useState<EGameState>(EGameState.None);

  const { setGameScreen, updateGameState, gameCode, gameType, gameLanguage } =
    useContext(GamestateContext);
  const { showPopup } = useContext(PopupContext);
  const { logoutUser } = useContext(AuthContext);

  useEffect(() => {
    logger.info(FormatMessageByCode(gameCode, "Main Menu - Screen Mounted"));
    let isMounted = true;
    if (isMounted) {
      setGameScreen(EGameScreen.Menu);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;
    let memberChannel = pusher.subscribe(gameCode);
    memberChannel.bind(CHANNEL_JOIN_SUCCESS, () => {});

    memberChannel.bind(CHANNEL_JOIN_FAILURE, (err: any) => {
      let { status } = err;
      if (status === 408 || status === 503) {
        // retry?
        memberChannel = pusher.subscribe(gameCode);
      }
    });
    memberChannel.bind(START_GAME, (data: any) => {
      applyModificationOnGameStatus(data);
    });

    return () => {
      memberChannel.unbind_all();
      isMounted = false;
      console.log("Menu Unmounted");
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      CacheImages([
        clock,
        xmark,
        twomen,
        active_menu_card,
        active_menu_card_result,
        ...getGameBackground(gameType),
      ]);
      logger.info(FormatMessageByCode(gameCode, "Main Menu - Cached Images"));
      getRoomAvailability();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const getRoomAvailability = async () => {
    setLoading(true);
    try {
      let response = await NetworkManager.GetMemberCount(gameCode);
      if (response.data.totalMembers > MAX_USERS) {
        logger.info(
          FormatMessageByCode(gameCode, "Main Menu - Member Limit Exceeded")
        );
        setLoading(false);
        let box = getMessageBox(
          EMessageType.Error,
          "Error",
          [
            <span>
              The maximum number of players is already logged in. Please try
              logging in with a different game code.
            </span>,
          ],
          <Button
            text={"CLOSE"}
            style={{ width: "235px", height: "53px", fontSize: "1em" }}
            onClick={() => showPopup(null)}
            autoFocus={true}
          ></Button>
        );
        showPopup(box);
        logoutUser();
        return;
      }
    } catch (err) {
      logoutUser();
      return;
    }
    getGameStatus();
  };
  const getGameStatus = async () => {
    let response = null;
    try {
      response = await NetworkManager.GetGameStatus(gameType, gameLanguage);
      logger.info(
        FormatMessageByCode(gameCode, "Main Menu - Fetch Game Status")
      );
      setLoading(false);
    } catch (err) {
      const error = err as any;
      response = error?.response;
      logger.info(
        FormatMessageByCode(gameCode, "Main Menu - Game Status Error")
      );
      logoutUser();
      return;
    }

    if (response.status === 200) {
      applyModificationOnGameStatus(response.data.gameStatus);
    }
  };

  const applyModificationOnGameStatus = (data: GameStatusResponse) => {
    const {
      timeStarted,
      timeEnded,
      result,
      gameContinued,
      totalTimePenalties,
    } = data;
    const updatedGameState = GetUpdateGameData(data);
    let passedTime =
      getSeconds(data.currentTime, timeStarted) + totalTimePenalties;

    if (timeStarted === "") {
      updateGameState(defaultGameState);
      setState(EGameState.Play);
      logger.info(
        FormatMessageByCode(gameCode, "Main Menu - Display Play Button")
      );
    } else if (timeEnded === "" && result === "") {
      if (
        gameType === GameTypes.BBH &&
        passedTime >= GAME_TIME &&
        (isBBHAllStepCompleted(updatedGameState) || !gameContinued)
      ) {
        updateGameState(updatedGameState);
        setState(EGameState.Result);
        return;
      }
      updateGameState(updatedGameState);
      setState(EGameState.Resume);
    } else {
      if (!isBBHAllStepCompleted(updatedGameState) && gameContinued) {
        updateGameState(updatedGameState);
        setState(EGameState.Resume);
        return;
      }
      updateGameState(updatedGameState);
      setState(EGameState.Result);
    }
  };

  const getGameState = (): string => {
    switch (state) {
      case EGameState.Play:
        cacheStepImages();
        cacheResultImages();
        return "GET STARTED";
      case EGameState.Resume:
        cacheStepImages();
        cacheResultImages();
        return "RESUME GAME";
      case EGameState.Result:
        cacheResultImages();
        return "VIEW RESULTS";
      default:
        return "GET STARTED";
    }
  };

  const cacheStepImages = () => {
    const StepImages: string[] = [];
    getGameQuestions(gameType, gameLanguage).forEach((src) => {
      src.Content.forEach((image) => {
        StepImages.push(image);
      });
    });
    const uniqueImages = new Set(StepImages);
    CacheImages(Array.from(uniqueImages));
  };
  const cacheResultImages = () => {
    const Images = getGameResult(gameType, gameLanguage).map((src) => {
      return src.Content;
    });
    const uniqueImages = new Set(Images);
    CacheImages(Array.from(uniqueImages));
  };
  const getNavigationPath = (): string => {
    switch (state) {
      case EGameState.Play:
        return ROUTE_START_INTRO;
      case EGameState.Resume:
        return ROUTE_RESUME_INTRO;
      case EGameState.Result:
        return ROUTE_RESULT;
      default:
        return "";
    }
  };

  return (
    <Fragment>
      {isLoading ? <CircularSpinner></CircularSpinner> : null}
      <div className={classes["menu"]}>
        <header
          className={`${classes["menu-header"]} ${getGameBGByScreen(
            "main_menu",
            gameType
          )}`}
        >
          <div className={classes["title-container"]}>
            <span style={{ fontSize: "1em" }}>
              {GetCompanyName() + getTranslatedWord(" presents", gameLanguage)}
            </span>
            <br></br>
            <span
              className={classes["time-ticking-title"]}
              style={{ fontSize: "1.8em", fontFamily: `${GetGameFont()}` }}
            >
              {getFullGameName(gameType)}
            </span>
          </div>

          <Container maxWidth="lg">
            <div className={`row ${classes["menu-card-container"]} mt-4`}>
              <Card
                label={"OUR GAMES"}
                logo_path={our_games_logo}
                onClick={() => {
                  window.open(GetWebsiteLink(gameLanguage), "_blank");
                }}
              ></Card>
              <Card
                label={"LEADERBOARD"}
                logo_path={leaderboard_logo}
                path={ROUTE_LEADERBOARD}
              ></Card>
              <Card
                label={"HOW TO PLAY"}
                logo_path={how_to_play_logo}
                path={ROUTE_HOW_TO_PLAY}
              ></Card>
              <Card
                label={getGameState()}
                logo_path={start_game_logo}
                path={getNavigationPath()}
              ></Card>
            </div>
          </Container>
          {/* {isCompany(EGameCompany.Experios) ? (
            <div className={classes["bottom-logo-container"]}>
              <span>SUPPORTED BY</span>
              <div className="mt-1">
                <img src={Victoria_Logo} alt="logo" height={52}></img>
              </div>
            </div>
          ) : null} */}
        </header>
      </div>
    </Fragment>
  );
};

export default withRouter(Menu);

export const isCompany = (companyName: string) => {
  const company = localStorage.getItem(GAME_COMPANY) || "";
  return companyName === company;
};
export const GetCompanyName = () => {
  const company = localStorage.getItem(GAME_COMPANY) || "";
  switch (company) {
    case EGameCompany.Enigma:
      return "Enigma Escapes";
    case EGameCompany.Reckitt:
      return "Reckitt";
    case EGameCompany.FunEmpire:
      return "The Fun Empire";
    case EGameCompany.Experios:
      return "Experios";
    case EGameCompany.EscapeRoom:
      return "Escape Rooms For You";
    case EGameCompany.EscapeHQ:
      return "Escape HQ";
    case EGameCompany.GetMeOut:
      return "Get Me Out Escape";
    case EGameCompany.GrandEscapeGame:
      return "Grand Escape Game";
    default:
      return "Experios";
  }
};

export const GetGameFont = () => {
  const gameType = localStorage.getItem(GAME_TYPE) || "";
  switch (gameType) {
    case GameTypes.MAYDAY:
      return "SkyFont";
    case GameTypes.BT:
      return "Treasuremap";
    default:
      return "Montserrat";
  }
};
export const GetWebsiteLink = (language: string): string => {
  const company = localStorage.getItem(GAME_COMPANY) || "";
  switch (company) {
    case EGameCompany.Experios:
      switch (language) {
        case GameLanguages.ENUK:
          return WEBSITE_LINK;
        case GameLanguages.DUTCH:
          return WEBSITE_LINK_NL;
        default:
          return WEBSITE_LINK;
      }
    case EGameCompany.FunEmpire:
      switch (language) {
        case GameLanguages.ENUK:
          return FUN_EMPIRE_WEBSITE_LINK;
        case GameLanguages.DUTCH:
          return FUN_EMPIRE_WEBSITE_LINK;
        default:
          return FUN_EMPIRE_WEBSITE_LINK;
      }
    case EGameCompany.EscapeRoom:
      switch (language) {
        case GameLanguages.ENUK:
          return ESCAPE_ROOM_WEBSITE_LINK;
        case GameLanguages.DUTCH:
          return ESCAPE_ROOM_WEBSITE_LINK;
        default:
          return ESCAPE_ROOM_WEBSITE_LINK;
      }
    case EGameCompany.Enigma:
      switch (language) {
        case GameLanguages.ENUK:
          return ENIGMA_WEBSITE_LINK;
        case GameLanguages.DUTCH:
          return ENIGMA_WEBSITE_LINK;
        default:
          return ENIGMA_WEBSITE_LINK;
      }
    case EGameCompany.EscapeHQ:
      switch (language) {
        case GameLanguages.ENUK:
          return ESCAPE_HQ_WEBSITE_LINK;
        case GameLanguages.DUTCH:
          return ESCAPE_HQ_WEBSITE_LINK;
        default:
          return ESCAPE_HQ_WEBSITE_LINK;
      }
    case EGameCompany.GetMeOut:
      switch (language) {
        case GameLanguages.ENUK:
          return ESCAPE_GET_ME_OUT_LINE;
        case GameLanguages.DUTCH:
          return ESCAPE_GET_ME_OUT_LINE;
        default:
          return ESCAPE_GET_ME_OUT_LINE;
      }
    case EGameCompany.GrandEscapeGame:
      switch (language) {
        case GameLanguages.ENUK:
          return GRAND_ESCAPE_GAME_LINK;
        case GameLanguages.DUTCH:
          return GRAND_ESCAPE_GAME_LINK;
        default:
          return GRAND_ESCAPE_GAME_LINK;
      }
    default:
      switch (language) {
        case GameLanguages.ENUK:
          return WEBSITE_LINK;
        case GameLanguages.DUTCH:
          return WEBSITE_LINK_NL;
        default:
          return WEBSITE_LINK;
      }
  }
};

export const GetCompany = (): string => {
  const company = localStorage.getItem(GAME_COMPANY) || "";
  return company;
};

export const GetUpdateGameData = (data: GameStatusResponse) => {
  let gamePassedTime =
    getSeconds(data.currentTime, data.timeStarted) + data.totalTimePenalties;
  let lastHintUsedTime = new Date(data.lastHintUsedTime).getTime();
  let gameStartTime = new Date(data.timeStarted).getTime();
  if (data.timeEnded === "" && gamePassedTime < GAME_TIME) {
    const state: IGameState = {
      gameStartTime: gameStartTime,
      gameTime: GAME_TIME - gamePassedTime,
      gameStepNo: data.currentGameStep,
      teamName: data.teamName,
      totalIncorrect: data.noIncorrectAnswers,
      totalUseHints: data.noHintsRequested,
      useHints: getCurrentStepUseHints(data.currentGameStep, data.hintsUsed),
      lastHintUsedTime: lastHintUsedTime,
      result:
        data.resultInAmount !== null && data.resultInAmount !== undefined
          ? data.resultInAmount
          : data.result,
      Step10Completed: data.step10Completed,
      step11Completed: data.step11Completed,
    };
    return state;
  } else {
    let state: IGameState = {
      gameStartTime: gameStartTime,
      gameTime: getTimeAfterPenalty(data),
      gameStepNo: data.currentGameStep,
      ...(IsGameTypeOf(GameTypes.BBH) &&
        data.gameContinued && { gameStepNo: data.resumeGameStep }),
      resumeGameStep: data.resumeGameStep,
      teamName: data.teamName,
      totalIncorrect: data.noIncorrectAnswers,
      totalUseHints: data.noHintsRequested,
      useHints: [],
      lastHintUsedTime: lastHintUsedTime,
      result:
        data.resultInAmount !== null && data.resultInAmount !== undefined
          ? data.resultInAmount
          : data.result,
      Step10Completed: data.step10Completed,
      step11Completed: data.step11Completed,
      gameContinued: data.gameContinued,
    };
    state = {
      ...state,
      useHints: getCurrentStepUseHints(state.gameStepNo, data.hintsUsed),
    };
    return state;
  }
};

export const getSeconds = (
  currentTime: string,
  previousTime: string
): number => {
  if (currentTime === undefined) {
    console.error("Current Time is Undefined");
  }
  let date = new Date(previousTime).getTime();
  let usedTime = (new Date(currentTime).getTime() - date) / 1000;
  return Clamp(usedTime, 0, GAME_TIME);
};

const getTimeAfterPenalty = (data: GameStatusResponse): number => {
  const { timeStarted, timeEnded, totalTimePenalties, currentTime } = data;
  let completeTime = gameCompletionTime(timeStarted, timeEnded, currentTime);
  let penaltyTime = totalTimePenalties;
  let totalTime = completeTime - penaltyTime;
  return Clamp(totalTime, 0, GAME_TIME);
};

export const getPenaltyTime = (incorrect: number, hints: number): number => {
  return (incorrect + hints) * getGamePenalty();
};
export const gameCompletionTime = (
  startTime: string,
  endTime: string,
  currentTime: string
): number => {
  let diff = 0;
  if (endTime === "") {
    // It means someone left the game and never try to complete that
    diff = new Date(currentTime).getTime() - new Date(startTime).getTime();
  } else {
    diff = new Date(endTime).getTime() - new Date(startTime).getTime();
  }
  let usedTime = GAME_TIME - diff / 1000;
  return Clamp(usedTime, 0, GAME_TIME);
};

const getCurrentStepUseHints = (
  step: string,
  usedHints: HintUsed[]
): string[] => {
  // debugger;
  const hints = usedHints.find((eachStep) => {
    return eachStep.gameStep === step;
  });
  return hints ? hints.hintsUsed : [];
};

const getGamePenalty = (): number => {
  const game = localStorage.getItem(GAME_TYPE) || "";
  switch (game) {
    case GameTypes.Island:
      return 0;
    default:
      return 30;
  }
};
export const GetGameType = (): string => {
  return localStorage.getItem(GAME_TYPE) || "";
};

export const IsGameTypeOf = (type: string): boolean => {
  return GetGameType() === type;
};
export const isBBHCompleted = ({ resumeGameStep }: any) => {
  return resumeGameStep ? true : false;
};

export const isBBHAllStepCompleted = ({
  gameStepNo,
  Step10Completed,
  step11Completed,
}: any) => {
  if (gameStepNo === "13" && Step10Completed && step11Completed) {
    return true;
  }
  return false;
};
