import * as winston from "winston";

const logger = winston.createLogger({
  level: "info",
  format: winston.format.combine(
    winston.format.timestamp({
      format: "YYYY-MM-DD HH:mm:ss",
    }),
    winston.format.json()
  ),
  transports: [
    new winston.transports.Console({
      level: "http",
    }),
  ],
});

export default logger;

export const FormatMessageByCode = (code: string, message: string) => {
  return code + " -- " + message;
};
