import React, { useState } from "react";
import classes from "./imagepanel.module.scss";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import Backdrop from "../../backdrop/backdrop";
import Button from "../../button/button";
import CFontAwesomeIcon from "../../customfonticon/cfontawesomicon";
import { IArea } from "../imageMapper/imageMapper";
import AreaPanel from "./areaPanel";

const maxScale = 2;
interface IZoomImage extends React.ImgHTMLAttributes<HTMLImageElement> {
  image: string;
  defaultScale?: number;
  onClosePress: any;
  dimensions?: number[];
  areas?: IArea[];
  onAreaClick?: (index: number) => void;
}
const ZoomImagePanel: React.FC<IZoomImage> = ({
  image,
  defaultScale = 0.75,
  onClosePress,
  dimensions = [],
  areas = [],
  onAreaClick = () => {},
}) => {
  const [isZoomIn, setZoomIn] = useState<boolean>(true);

  const onZoomValueChanged = ({ scale }: any) => {
    if (scale >= maxScale) {
      setZoomIn(false);
    } else if (scale <= defaultScale) {
      setZoomIn(true);
    }
  };
  return (
    <Backdrop>
      <div
        className={classes["zoom-content-container"]}
        style={{ cursor: isZoomIn ? "zoom-in" : "zoom-out" }}
      >
        <span
          style={{
            fontSize: "1.2em",
            color: `${"red"}`,
            display: "flex",
            alignItems: "center",
            alignSelf: "self-start",
            marginBottom: "20px",
          }}
        >
          <CFontAwesomeIcon
            parentIcon="fas fa-circle"
            childIcon={"fas fa-image"}
            style={{ fontSize: "1.8em" }}
          ></CFontAwesomeIcon>
          &nbsp;&nbsp;{"Double click on image to zoom in and out"}
        </span>
        <TransformWrapper
          defaultScale={defaultScale}
          options={{
            minScale: defaultScale,
            maxScale: maxScale,
          }}
          pan={{ paddingSize: 0, animationTime: 0 }}
          doubleClick={{ step: 150, mode: isZoomIn ? "zoomIn" : "zoomOut" }}
          onZoomChange={(value: any) => {
            onZoomValueChanged(value);
          }}
        >
          <TransformComponent>
            <AreaPanel
              viewerImage={image}
              linkImage={image}
              onAreaClick={onAreaClick}
              dimensions={dimensions}
              areas={areas}
              style={{
                minWidth: "25%",
                maxWidth: "100%",
                width: "1400px",
              }}
            ></AreaPanel>
          </TransformComponent>
        </TransformWrapper>

        <div className={classes["content-container-text"]}>
          <Button
            text="CLOSE"
            style={{
              fontSize: "1em",
              alignSelf: "center",
              marginTop: "20px",
              width: "255px",
              height: "58px",
            }}
            onClick={() => {
              if (onClosePress) onClosePress();
            }}
          ></Button>
        </div>
      </div>
    </Backdrop>
  );
};

//export default ImagePanel;

export default React.memo(
  ZoomImagePanel,
  function arePropsAreEqual(prev, next) {
    return prev.image === next.image;
  }
);
