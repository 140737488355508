import React, { useContext } from "react";
import { GamestateContext } from "../../../context/GameState/GameStateContext";
import { getTranslatedWord } from "../../../metadata/ButtonText";
import classes from "./hintslot.module.scss";

interface IHintSlot {
  hintNo: number;
  text: string;
}

const defualtProps: IHintSlot = {
  hintNo: 0,
  text: "Hint",
};

const HintSlot: React.FC<IHintSlot> = ({ hintNo, text }) => {
  const { gameLanguage } = useContext(GamestateContext);

  function NewlineText(text: string) {
    return text.split("\\n").map((str: any, index: any) => (
      <span key={index}>
        {str}
        <br />
      </span>
    ));
  }

  const highlightedText =
    hintNo + 1 === 4
      ? getTranslatedWord("ANSWER", gameLanguage) + ":"
      : `HINT\xA0${hintNo + 1}:`;
  return (
    <div className={`${classes["hint"]} mt-2`}>
      <span style={{ color: "#7ED321" }}>{highlightedText}</span>
      <span style={{ marginLeft: "5px", marginRight: "5px" }}>
        {NewlineText(text)}
      </span>
    </div>
  );
};

HintSlot.defaultProps = defualtProps;
export default HintSlot;
