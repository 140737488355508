import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./hud.module.scss";
import InfoSlot from "../infoslot/infoslot";
import AudioSlot, { BGMusic } from "../infoslot/audioSlot";
import { GamestateContext } from "../../../context/GameState/GameStateContext";
import { styleTime } from "../../../utilities/util";
import {
  EGameCompany,
  EGameScreen,
  GameTypes,
} from "../../../container/Enums/Enums";
import { getTranslatedWord } from "../../../metadata/ButtonText";
import {
  GAME_COMPANY,
  GAME_TIME,
  HINT_AVAILABILITY,
  MAX_REQUESTED_HINT,
} from "../../../constants";
import { ParseResultByGame } from "../../../container/Leaderboard/Leaderboard";
import { isBTGameStep4 } from "../questionpanel/choosepanel";

const Hud = () => {
  const {
    gameTime,
    lastHintUseTime,
    useHints,
    gameLanguage,
    gameStepNo,
    gameType,
  } = useContext(GamestateContext);
  const [hintStatus, setHintStatus] = useState<string>("NOW");
  const [refresh, setRefresh] = useState<boolean>(false);
  const isMounted = useRef<boolean>(false);
  useEffect(() => {
    isMounted.current = true;
    const interval = setInterval(() => {
      if (isMounted.current) {
        setRefresh(!refresh);
      }
    }, 1000);
    return () => {
      isMounted.current = false;
      clearInterval(interval);
    };
  }, [refresh]);
  useEffect(() => {
    let remTime = (new Date().getTime() - lastHintUseTime) / 1000;
    if (isBTGameStep4(gameType, gameStepNo)) {
      setHintStatus("N/A");
    } else {
      if (remTime >= 0 && remTime <= HINT_AVAILABILITY) {
        setHintStatus(
          useHints.length < MAX_REQUESTED_HINT
            ? styleTime(HINT_AVAILABILITY - remTime) + " secs"
            : "N/A"
        );
      } else {
        setHintStatus(useHints.length < MAX_REQUESTED_HINT ? "NOW" : "N/A");
      }
    }
  }, [gameTime, refresh]);

  return (
    <div className={classes["hud-panel"]}>
      <InfoSlot
        title={getTranslatedWord("NEXT HINT AVAILABLE", gameLanguage)}
        titleStyle={{ fontSize: "0.6em", fontWeight: "normal" }}
        message={getTranslatedWord(hintStatus, gameLanguage)}
        messageStyle={{
          fontSize: "1em",
          fontWeight: "normal",
        }}
        style={{
          width: "220px",
          height: "75px",
          justifyContent: "center",
          padding: "0 0",
        }}
      ></InfoSlot>
      {!isBTGameStep4(gameType, gameStepNo) && (
        <InfoSlot
          title={getTranslatedWord("HINT PENALTY", gameLanguage)}
          titleStyle={{ fontSize: "0.6em" }}
          message="-30s"
          messageStyle={{ fontSize: "1em" }}
          style={{
            width: "220px",
            height: "75px",
            justifyContent: "center",
            padding: "0 0",
          }}
        ></InfoSlot>
      )}
      <InfoSlot
        title={getTranslatedWord("INCORRECT ANSWER PENALTY", gameLanguage)}
        titleStyle={{ fontSize: "0.6em" }}
        titleFontSize="0.6em"
        message="-30s"
        messageStyle={{ fontSize: "1em" }}
        style={{
          width: "220px",
          height: "75px",
          justifyContent: "center",
          padding: "0 0",
        }}
      ></InfoSlot>

      {isBTGameStep4(gameType, gameStepNo) && (
        <InfoSlot
          title={getTranslatedWord("SKIP CHALLENGE PENALTY", gameLanguage)}
          titleStyle={{ fontSize: "0.6em" }}
          message="-120s"
          messageStyle={{ fontSize: "1em" }}
          style={{
            width: "220px",
            height: "75px",
            justifyContent: "center",
            padding: "0 0",
          }}
        ></InfoSlot>
      )}
    </div>
  );
};

export default Hud;

export const TopHud = () => {
  const {
    totalIncorrect,
    totalUseHints,
    gameTime,
    deductTime,
    screen,
    gameType,
    gameLanguage,
    gameState,
  } = useContext(GamestateContext);

  let stopTimer = useRef<boolean>(false);
  let timer = useRef<number>(GAME_TIME);
  useEffect(() => {
    if (stopTimer.current === false && !gameState.gameContinued) {
      const interval = setInterval(() => {
        if (stopTimer.current === false) {
          deductTime(timer.current - 1);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [stopTimer.current]);

  useEffect(() => {
    if (gameTime <= 0 || screen === EGameScreen.Result) {
      stopTimer.current = true;
    }
    timer.current = gameTime;
  }, [gameTime, screen]);

  // const getAudioSlot = () => {
  //   if (gameType === GameTypes.BBH) {
  //     return (
  //       <AudioSlot
  //         style={{
  //           width: "159px",
  //           height: "81px",
  //         }}
  //         path={BGMusic}
  //       ></AudioSlot>
  //     );
  //   }
  //   return null;
  // };
  return (
    <div className={classes["game-hud"]}>
      <span style={{ fontSize: "1.3em" }}>{getFullGameName(gameType)}</span>
      <div className={classes["slot-container"]}>
        <InfoSlot
          color="red"
          title={styleTime(gameTime) + ""}
          message={getTranslatedWord("REMAINING", gameLanguage)}
          style={{
            width: "159px",
            height: "81px",
            marginRight: "10px",
          }}
        ></InfoSlot>
        <InfoSlot
          title={totalIncorrect + ""}
          message={getTranslatedWord("INCORRECT", gameLanguage)}
          style={{
            width: "159px",
            height: "81px",
            marginRight: "10px",
          }}
        ></InfoSlot>
        <InfoSlot
          title={totalUseHints + ""}
          message={getTranslatedWord("HINTS USED", gameLanguage)}
          style={{
            width: "159px",
            height: "81px",
            marginRight: "10px",
          }}
        ></InfoSlot>
        <AudioSlot
          style={{
            width: "159px",
            height: "81px",
            alignItems: "center",
            justifyContent: "center",
          }}
          path={BGMusic}
        ></AudioSlot>
      </div>
    </div>
  );
};

export const ResultHud = () => {
  const {
    totalIncorrect,
    totalUseHints,
    gameTime,
    gameType,
    gameLanguage,
    gameResult,
  } = useContext(GamestateContext);

  const getMoneySlot = () => {
    if (gameType === GameTypes.BBH) {
      return (
        <InfoSlot
          color="green"
          title={ParseResultByGame(GameTypes.BBH, gameResult)}
          titleStyle={{ fontSize: "1.3em" }}
          message={getTranslatedWord("LOOT COLLECTED", gameLanguage)}
          style={{
            width: "182px",
            height: "81px",
            marginLeft: "10px",
            paddingTop: "18px",
          }}
        ></InfoSlot>
      );
    }
    return null;
  };
  return (
    <div className={classes["result-hud-slot"]}>
      <InfoSlot
        title={totalIncorrect + ""}
        message={getTranslatedWord("INCORRECT ANSWERS", gameLanguage)}
        style={{
          width: "182px",
          height: "81px",
          marginRight: "10px",
        }}
      ></InfoSlot>
      <InfoSlot
        title={totalUseHints + ""}
        message={getTranslatedWord("HINTS USED", gameLanguage)}
        style={{
          width: "182px",
          height: "81px",
          marginRight: "10px",
        }}
      ></InfoSlot>
      <InfoSlot
        color={gameType === GameTypes.BBH ? "" : "green"}
        title={styleTime(gameTime) + ""}
        message={getTranslatedWord("TIME REMAINING", gameLanguage)}
        style={{
          width: "182px",
          height: "81px",
        }}
      ></InfoSlot>
      {getMoneySlot()}
    </div>
  );
};

export const getFullGameName = (gameType: string): string => {
  switch (gameType) {
    case GameTypes.TTJr:
      return getFullGameNameByCompany();
    case GameTypes.BBH:
      return "BEN'S BIG HEIST";
    case GameTypes.JH:
      return "JACK'S HANGOVER";
    case GameTypes.MAYDAY:
      return "MAYDAY";
    case GameTypes.BT:
      return "BLACKEYE'S TREASURE";
    default:
      return "TIME'S TICKING";
  }
};
const getFullGameNameByCompany = (): string => {
  const company = localStorage.getItem(GAME_COMPANY) || "";

  switch (company) {
    case EGameCompany.Reckitt:
      return "TIME'S TICKING";
    default:
      return "TIME'S TICKING JR";
  }
};
